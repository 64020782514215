import React from "react"
import styled from "styled-components"

import AppLayout from "@components/layout/App"

import CAREER_TRACKS from "@constants/career-tracks.es"

const ALL_SPECIALIZATIONS = CAREER_TRACKS.map(ct => ct.specializations).flat()

const Content = styled.div`
  padding: 1rem 1.5rem;
`

const HeaderFlex = styled.div`
  display: flex;

  padding-bottom: 24px;

  h1 {
    flex-grow: 5;
  }
`

const DownloadCareerMapDiv = styled.div`
  padding: 12px;
  margin: auto;
  border: 2px solid ${props => props.theme.colors.textBlack};
  font-weight: 500;
  text-align: center;

  > a {
    text-decoration: none;
    color: ${props => props.theme.colors.textBlack};
  }

  @media (min-width: 1024px) {
    cursor: pointer;
    padding: 12px 24px;
  }
`

const DownloadCareerMapButton = ({ pdf }) => {
  return (
    <DownloadCareerMapDiv>
      {/* TODO: url file */}
      <a href={pdf} target="_blank" rel="noopener noreferrer">
        Download Career Map
      </a>
    </DownloadCareerMapDiv>
  )
}

const ImageDiv = styled.div`
  overflow: hidden;

  // Remove the space between <img> https://stackoverflow.com/a/35216227
  font-size: 0;
`

const Page = props => {
  const { pageContext } = props

  const specialization = ALL_SPECIALIZATIONS.find(
    sp => sp.slug === pageContext.slug
  )

  return (
    <AppLayout>
      <Content>
        <HeaderFlex>
          <h1>{pageContext.name}</h1>
          {<DownloadCareerMapButton pdf={specialization.pdf} />}
        </HeaderFlex>
      </Content>

      <ImageDiv>
        {specialization.svgs.map((svg, index) => {
          const Component = svg
          return <Component key={`svg-component-${index}`} />
        })}
      </ImageDiv>
    </AppLayout>
  )
}

export default Page
